import React from 'react';
import MarverAppLayout from "./AppLayout"
// @ts-ignore
import fetch from 'node-fetch';

import { configure } from '@amzn/sentry-fetch';

const USE_DEV = false;

export class App extends React.Component {
    render() {


        return (
            <>
                <MarverAppLayout />
            </>
        );
    }
}

export default App;
