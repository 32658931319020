import React, { useState, useCallback } from "react"
import Masthead, {
    MastheadTitle,
    MastheadLink,
    MastheadMenuButton,
} from "@amzn/meridian/masthead"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import searchIconTokens from "@amzn/meridian-tokens/base/icon/search"
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog"

export default function MarverMastHead() {
    const [selected, setSelected] = useState("one")
    const onClickLink = useCallback(id => () => setSelected(id), [])

    return (
        <Masthead>
            <MastheadMenuButton onClick={() => { }} open={true} />
            <MastheadTitle href="#">
                <img src="https://meridian.a2z.com/static/m-light-aac418ea157ade34965f4477617b520d.svg" />
            </MastheadTitle>
            <MastheadLink selected={selected === "one"} onClick={onClickLink("one")}>
                Link one
            </MastheadLink>
            <MastheadLink selected={selected === "two"} onClick={onClickLink("two")}>
                Link two
            </MastheadLink>
            <MastheadLink
                selected={selected === "three"}
                onClick={onClickLink("three")}
                disabled={true}
            >
                Link three
            </MastheadLink>
            <Button type="icon" size="small" onClick={() => { }}>
                <Icon tokens={searchIconTokens}>Search</Icon>
            </Button>
            <Button type="icon" size="small" onClick={() => { }}>
                <Icon tokens={cogIconTokens}>Settings</Icon>
            </Button>
        </Masthead>
    )
}
