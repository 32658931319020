import React, { useState, useEffect } from 'react';

import AppLayout from "@amzn/meridian/app-layout"
import Masthead from "@amzn/meridian/masthead"
import SideMenu from "@amzn/meridian/side-menu"
import Input from "@amzn/meridian/input"
import MarverMastHead from "./masthead/MastHead"
import SearchField from "@amzn/meridian/search-field"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"

import { configure } from '@amzn/sentry-fetch';


const USE_DEV = false;

function MarverAppLayout() {
    return (
        <AppLayout
            headerComponent={Masthead}
            sidebarComponent={SideMenu}

            backgroundColor="alternateSecondary"
        >
            <MarverMastHead />
            <CalloutData />

        </AppLayout>
    )
}

function CalloutData() {
    const dev = 'http://creigw-v2.aka.corp.amazon.com:5001/api/callout/';
    const beta = 'https://marver.corp.amazon.com/api/callout/';

    let url = beta;
    let f = configure({ followMidwayStepUp: true, followMidwayStepUpOptions: { mode: 'modal' } });

    if (USE_DEV) {
        // use normal fetch when using dev server
        url = dev;
        // @ts-ignore
        f = fetch;
    }

    const [data, setData] = useState('');
    const [id, setId] = useState<string>('');

    async function fetchData() {
        let apiUrl = url + id
        const response = await f(apiUrl);
        const data = await response.json();
        setData(data);
    }

    return (
        <Column>
            <Row>
                <SearchField
                    value={id}
                    onChange={setId}
                    placeholder="Search for..."
                    onSubmit={fetchData}
                />
            </Row>
            <Row>
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </Row>

        </Column>
    )
}

export default MarverAppLayout